export default class Navigation {
    constructor() {
        Navigation.sticky()
        Navigation.submenu()
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const offset = 120 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scroll_top = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scroll_top >= last_scroll_top && scroll_top > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('.has-banner').length > 0) {
                full = true
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('header__navbar--ontop')
                navbar.addClass('header__navbar--onscroll')
                if (full === true) {
                    Navigation.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('header__navbar--onscroll')
                navbar.addClass('header__navbar--ontop')
                if (full === true) {
                    Navigation.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        const logo_banner = IRISCollectionCustomer.imageLogoBanner
        const logo_classic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logo_banner)
        } else if (color === 'classic') {
            logo.attr('src', logo_classic)
        }
    }

    static submenu() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
            if ($('body').hasClass('menu-open')) {
                Navigation.changeLogoColor('banner')
                $('.eco-bar').addClass('eco-bar--hidden')
            } else {
                if ($('body').hasClass('header-is-hidden') || $(window).scrollTop() === 0) {
                    Navigation.changeLogoColor('banner')
                } else {
                    Navigation.changeLogoColor('classic')
                }
            }
            if ($('body').hasClass('submenu-open')) {
                $('body').removeClass('submenu-open')
                $(submenu).removeClass('submenu-open')
            }
        })

        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
            $(this).closest('.menu__item').toggleClass('has-submenu-open')
            $('body').toggleClass('submenu-open')
        })

        $(prev).on('click', function (e) {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
            $(this).closest('.menu__item').removeClass('has-submenu-open')
            $('body').toggleClass('submenu-open')
        })

        // Opacity links management
        $('.first_nav li.menu__item')
            .on('mouseenter', function () {
                $('.first_nav li.menu__item').addClass('opacity')
                $(this).addClass('current')
            })
            .on('mouseleave', function () {
                $(this).removeClass('current')
                $('.first_nav li.menu__item').removeClass('opacity')
            })
    }
}
