import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class Header {
    constructor() {
        Header.swiper_banner()
        Header.langs_switcher()
    }

    static async swiper_banner() {
        const swiperItem = '[data-swiper="banner"]'
        const slideItem = '[data-swiper="banner"] > div'

        const options = {
            slidesPerView: 'auto',
            autoplay: {
                delay: 5000,
            },
            speed: 1500,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: '#swiper-banner-pagination',
                type: 'bullets',
            },
            navigation: {
                nextEl: '#swiper-banner-next',
                prevEl: '#swiper-banner-prev',
            },
            mousewheel: {
                forceToAxis: true,
            },
            grabCursor: true,
            loop: true,
            on: {
                afterInit: (swiper) => {
                    Header.updateNavigation(swiper)
                },
                init: (swiper) => {
                    Header.animationStart(swiper)
                },
                slideChangeTransitionStart: (swiper) => {
                    Header.animationStart(swiper)
                },
                slideChangeTransitionEnd: (swiper) => {
                    Header.updateNavigation(swiper)
                },
            },
        }

        $(swiperItem).append(
            '<span id="swiper-banner-next" class="hc-slider-button-next"></span><span id="swiper-banner-pagination" class="hc-slider-pagination"></span>',
        )
        $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

        await Swiper.create('[data-swiper="banner"]', options)
    }

    static updateNavigation(swiper) {
        const total_slides = $(swiper.el).find('.swiper-slide:not(.swiper-slide-duplicate)').length

        if (swiper.navigation.nextEl) {
            let next_index = swiper.realIndex + 1
            if (next_index > total_slides - 1) {
                next_index = 0
            }

            const $nextElement = $(swiper.el).find(`[data-swiper-slide-index="${next_index}"]`)
            const nextImageUrl = $nextElement.data('image-nav')
            const img = $(swiper.navigation.nextEl).find('img')
            if (!img.length) {
                $(swiper.navigation.nextEl).html(`<img src="${nextImageUrl}" alt="" />`)
            }
            img.fadeOut(250, () => {
                img.attr('src', nextImageUrl)
            }).fadeIn(250)
        }
    }

    static animationStart(swiper) {
        if ($(window).width() >= 1000) {
            // set div outside the screen
            $('.swiper-slide:not(.swiper-slide-visible) .header__heading__overtitle').animate(
                { opacity: 0 },
                0,
            )
            $('.swiper-slide:not(.swiper-slide-visible) .header__heading__title').animate(
                { left: 500, opacity: 0 },
                0,
            )
            $('.swiper-slide:not(.swiper-slide-visible) .header__heading__subtitle').animate(
                { left: 600, opacity: 0 },
                0,
            )
            $('.swiper-slide:not(.swiper-slide-visible) .header__heading__credits').animate(
                { left: 700, opacity: 0 },
                0,
            )

            // div apparition
            setTimeout(() => {
                $('.swiper-slide-visible .header__heading__overtitle')
                    .delay(1000)
                    .animate({ opacity: 1 }, 500)
                $('.swiper-slide-visible .header__heading__title').animate(
                    { left: 15, opacity: 1 },
                    1000,
                )
                $('.swiper-slide-visible .header__heading__subtitle').animate(
                    { left: 15, opacity: 1 },
                    1000,
                )
                $('.swiper-slide-visible .header__heading__credits').animate(
                    { left: 15, opacity: 1 },
                    1000,
                )
            }, 10)
        }
    }

    static langs_switcher() {
        const current_lang = $('.lang__current')
        const list_langs = $('.lang__list')

        const current_lang_value = current_lang.first().text()

        //Hide submenu
        list_langs.hide()

        //Open submenu
        $('.tools__item__languages').on('click', () => {
            $(list_langs).toggleClass('lang__list--open')
            $('.lang__list--open').fadeIn(250)
            $('.lang__list:not(.lang__list--open)').fadeOut(250)
        })

        //Remove current language on list (init)
        $('.lang__list > a').each(function () {
            if ($(this).text() === current_lang_value) {
                $(this).hide()
            }
        })

        //Change Navbar Language on click
        $('.lang__list > a').on('click', function () {
            $('.lang__list > a').show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })

        // Fix current lang
        let currentLang = document.getElementsByTagName('html')[0].getAttribute('lang')
        if (currentLang.length > 2) {
            currentLang = currentLang.split('-')[0]
        }
        $('.langSwitcher_current').html(currentLang.toUpperCase())
    }
}
